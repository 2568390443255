import React from 'react';
import { Router } from '@reach/router';
import PrivateRoute from '../components/privateRoute';
import Dashboard from '../components/Dashboard';
import Login from '../components/login';

const Admin = () => (
  <Router>
    <PrivateRoute path="/admin/orderlist" component={Dashboard} />
    <Login path="/admin/login" />
  </Router>
);

export default Admin;
