import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import Inventory from './Inventory';
import OrderList from './OrderList';
import { isLoggedIn, logout } from '../utils/auth'; // highlight-line
import { navigate } from 'gatsby'; // highlight-line
// require(`dotenv`).config();
import { Link } from 'gatsby';

const client = new ApolloClient({
  uri: 'https://api-eu-central-1.graphcms.com/v2/ckdrrcioy0k8401z1bfusegqy/master',
  // uri: process.env.GRAPHCMS_ENDPOINT,
  headers: {
    token: process.env.GRAPHCMS_OPEN_TOKEN,
  },

  cache: new InMemoryCache(),
});
const Dashboard = () => {
  return (
    <ApolloProvider client={client}>
      <div
        className="flex flex-col justify-between bg-primary mt-18 md:mt-22 lg:mt-14"
      >
        <h1 className="p-8 text-6xl font-bold text-white">dashboard</h1>
        {isLoggedIn() ? (
          <Link
            to="/"
            className="w-full text-white md:w-1/3 primary-btn"
            onClick={(event) => {
              event.preventDefault();
              logout(() => navigate(`/admin/login`));
            }}
          >
            logout
          </Link>
        ) : null}
      </div>
      <OrderList />
      <Inventory />
    </ApolloProvider>
  );
};

export default Dashboard;
