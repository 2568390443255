import React from 'react';
import { navigate } from 'gatsby';
import { handleLogin, isLoggedIn } from '../utils/auth';

class Login extends React.Component {
  state = {
    username: ``,
    password: ``,
  };

  handleUpdate = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    handleLogin(this.state);
  };

  render() {
    if (isLoggedIn()) {
      navigate(`/admin/orderlist`);
    }

    return (
      <section
        className="flex flex-col flex-wrap justify-between w-full mt-16 bg-primary md:mt-20 lg:mt-12"
      >
        <div className="flex flex-col justify-between bg-primary">
          <h1 className="p-8 text-6xl font-bold text-white">Login</h1>
        </div>
        <form
          className="flex flex-col p-20 m-auto bg-primary"
          method="post"
          onSubmit={(event) => {
            this.handleSubmit(event);
            navigate(`/admin/orderlist`);
          }}
        >
          <label className="text-white bg-transparent ">
            <input
              placeholder="username"
              type="text"
              name="username"
              style={{
                padding: '2rem',
                border: '2px white solid',
              }}
              onChange={this.handleUpdate}
              className="text-white border focus:outline-none"
            />
          </label>
          <br />
          <br />
          <br />
          <br />

          <label className="text-white bg-transparent ">
            <input
              type="password"
              style={{
                padding: '2rem',
                border: '2px white solid',
              }}
              name="password"
              onChange={this.handleUpdate}
              htmlFor="password"
              placeholder="password"
              className="text-white focus:outline-none"
            />
          </label>
          <br />
          <input type="submit" className="primary-btn" value="Log In" />
        </form>
      </section>
    );
  }
}

export default Login;
