import { useQuery, gql } from '@apollo/client';
import Seo from './SEO';
import React from 'react';
const QUERY = gql`
  {
    products {
      name
      orderItems {
        quantity
        size
      }
      stockSystems {
        stockCount
        size
      }
    }
  }
`;
const Inventory = () => {
  const { loading, error, data } = useQuery(QUERY);
  if (loading) {
    return <h1 className="loading">loading</h1>;
  }
  if (error) {
    return <p>Error occured while fetchin data</p>;
  }

  // Quick and simple export target #table_id into a csv
  function download_table_as_csv(table_id, separator = ',') {
    // Select rows from table_id
    var rows = document.querySelectorAll('table#' + table_id + ' tr');
    // Construct csv

    var csv = [];
    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll('td, th');
      for (var j = 0; j < cols.length; j++) {
        // Clean innertext to remove multiple spaces and jumpline (break csv)
        var data = cols[j].innerText
          .replace(/(\r\n|\n|\r)/gm, '')
          .replace(/(\s\s)/gm, ' ');
        // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
        data = data.replace(/"/g, '""');
        // Push escaped string
        row.push('"' + data + '"');
      }
      csv.push(row.join(separator));
    }
    var csv_string = csv.join('\n');
    // Download it
    var filename =
      'export_' + table_id + '_' + new Date().toLocaleDateString() + '.csv';
    var link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('target', '_blank');
    link.setAttribute(
      'href',
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string)
    );
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <div>
      <Seo title="Dashboard" />
      <div className="flex flex-wrap">
        <div className="flex flex-row justify-between w-full p-4 border border-primary text-primary">
          <h2 className="pt-4 text-4xl font-bold">stock</h2>
          <div className="w-60">
            <button
              className="ml-0 -mt-2 secondary-btn sm:-ml-2 md:mt-0"
              onClick={() => download_table_as_csv('inventory-table')}
            >
              download CSV
            </button>
          </div>
        </div>
        <table className="-mt-px order-table" id="inventory-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Size</th>
              <th>Total inventory</th>
              <th>Sold</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {data.products.map((product) => {
              return product.stockSystems.map((Stock, key) => {
                var totoalsold = 0;
                product.orderItems.map((soldout) => {
                  if (soldout.size === Stock.size) {
                    return (totoalsold += soldout.quantity);
                  } else return null;
                });
                return (
                  <tr key={key}>
                    <td>{product.name}</td>
                    <td>{Stock.size}</td>
                    <td>{Stock.stockCount}</td>
                    <td>{totoalsold}</td>

                    {Stock.stockCount - totoalsold <= 0 ? (
                      <td className="bg-green-50">
                        <p className="text-lg font-bold text-secondary">
                          sold out
                        </p>
                      </td>
                    ) : (
                      <td>
                        <p className="text-lg font-bold text-primary">
                          selling
                        </p>
                      </td>
                    )}
                  </tr>
                );
              });
            })}
            {/* {data.products.map((product, key) => {
              return              product.stockSystems.map((Stock) => {
                return (
                  <tr >
                  <td>{product.size}</td>
                  {product.stockSystems.map((Stock) => (
                    <div>
                     
                    </div>
                  ))}{' '}
                </tr>
                )
              }
                            
            })}                 */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Inventory;
