import React, { useRef, useState } from 'react';
import Seo from './SEO';
import { useQuery, useMutation, gql } from '@apollo/client';
import axios from 'axios';

const MUTATION = gql`
  mutation UpdatedOrder(
    $id: ID!
    $fulfill: Boolean!
    $tracking_number: String!
    $provider: String
  ) {
    updateOrder(
      where: { id: $id }
      data: {
        fulfilled: $fulfill
        tracking_number: $tracking_number
        provider: $provider
      }
    ) {
      id
    }
  }
`;
const QUERY = gql`
  {
    orders {
      id
      name
      phone
      email
      total
      fulfilled
      createdAt
      tracking_number
      provider
      orderItems {
        name
        size
      }
      shippingAddress {
        address1
        address2
        city
        country
        name

        state
        zip
      }
    }
  }
`;
const OrderList = () => {
  const [updateorder] = useMutation(MUTATION);
  const { loading, error, data } = useQuery(QUERY);
  const [trackingNo, setTrackingNo] = useState('');
  const [provider, setProvider] = useState('');
  const timerRef = useRef(null);
  const orderID = useRef(null);
  if (loading) {
    return <h1 className="container loading">loading</h1>;
  }
  if (error) {
    return <p className="container loading">Error occured </p>;
  }

  // Quick and simple export target #table_id into a csv
  function download_table_as_csv(table_id, separator = ',') {
    // Select rows from table_id
    var rows = document.querySelectorAll('table#' + table_id + ' tr');
    // Construct csv

    var csv = [];
    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll('td, th');
      for (var j = 0; j < cols.length; j++) {
        // Clean innertext to remove multiple spaces and jumpline (break csv)
        var data = cols[j].innerText
          .replace(/(\r\n|\n|\r)/gm, '')
          .replace(/(\s\s)/gm, ' ');
        // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
        data = data.replace(/"/g, '""');
        // Push escaped string
        row.push('"' + data + '"');
      }
      csv.push(row.join(separator));
    }
    var csv_string = csv.join('\n');
    // Download it
    var filename =
      'export_' + table_id + '_' + new Date().toLocaleDateString() + '.csv';
    var link = document.createElement('a');
    link.style.display = 'none';
    link.setAttribute('target', '_blank');
    link.setAttribute(
      'href',
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string)
    );
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function DispatchOrder() {
    try {
      alert('Dispatching');
      timerRef.current = setTimeout(FullfilledOrder, 600);
    } catch (error) {
      console.log(error);
    }
  }

  function FullfilledOrder() {
    updateorder({
      variables: {
        id: orderID.current,
        fulfill: true,
        tracking_number: trackingNo,
        provider: provider,
      },
      refetchQueries: [{ query: QUERY }],
    });
    alert(`Dispatched Order ID: ${orderID.current}`);
  }

  const HandleSubmit = (id) => {
    orderID.current = id;
    DispatchOrder();
  };

  function CancelOrder() {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }

  const NotionExport = async (data) => {
    console.log('order data', data);
    var orderData = {
      order: {
        id: data.id,
        createdAt: data.createdAt,
        name: data.name,
        email: data.email,
        total: data.total.toString(),
        phone_number: data.phone,
        shippingAddress: {
          address1: data.shippingAddress.address1,
          address2: data.shippingAddress.address2,
          city: data.shippingAddress.city,
          zip: data.shippingAddress.zip,
        },
        orderItems: data.orderItems
          .map((item) => item.name.concat(`(${item.size})`))
          .toString(),
        fulfilled: true,
      },
    };

    const response = await fetch('/.netlify/functions/notionapi', {
      method: 'POST',
      headers: {
        'cache-control': 'no-cache',
        'content-type': 'application/json',
      },
      body: JSON.stringify(orderData),
    });

    try {
      // Send dispatch email
      const emailResponse = await axios.put(
        '/.netlify/functions/order-dispatched',
        JSON.stringify(orderData)
      );
      if (emailResponse.status !== 200) {
        alert('Error in sending email');
      }
      // todo:: Alert can be removed here if it is annoying.
      alert('Email sent successfully');
    } catch (err) {
      alert('Error in sending email: ' + JSON.stringify(err));
    }
  };

  return (
    <div className="container">
      <Seo title="Dashboard" />
      {/* {isLoggedIn() ? (
        <a
          href="/"
          onClick={(event) => {
            event.preventDefault();
            logout(() => navigate(`/admin/login`));
          }}
        >
          Logout
        </a>
      ) : null} */}
      <div className="flex flex-wrap">
        <div className="flex flex-row justify-between w-full p-4 bg-white border text-primary border-primary">
          <h2 className="pt-4 text-4xl font-bold">orders</h2>
          <div className="w-60">
            <button
              className="w-16 ml-0 -mt-2 secondary-btn sm:-ml-2 md:mt-0"
              onClick={() => download_table_as_csv('order-list-table')}
            >
              export CSV
            </button>
          </div>
        </div>
        <table className="order-table" id="order-list-table">
          <thead>
            <tr>
              <th>I/O</th>
              <th>Date</th>
              <th>Name</th>
              {/* <th>Phone</th> */}
              {/* <th>Email</th> */}
              <th>Address</th>
              <th>City</th>
              <th>Postcode</th>
              <th>Items</th>
              {/* <th>Total</th> */}
              {/* <th>Tracking ID</th> */}
              {/* <th>Provider</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.orders.map((order, key) => {
              return (
                <tr key={key}>
                  <td>
                    <input
                      disabled={true}
                      type="checkbox"
                      checked={order.fulfilled}
                    ></input>
                  </td>
                  <td>{new Date(order.createdAt).toLocaleString()}</td>
                  <td>{order.name.toUpperCase()}</td>
                  {/* <td>{order.phone}</td>
                  <td>{order.email}</td> */}
                  <td>
                    {order.shippingAddress.address1.toUpperCase()},
                    {order.shippingAddress.address2.toUpperCase()}
                  </td>
                  <td>{order.shippingAddress.city.toUpperCase()}</td>
                  <td>{order.shippingAddress.zip.toUpperCase()}</td>
                  <td>
                    {order.orderItems.map((item) => {
                      return (
                        <div>
                          <p>
                            {item.name.toUpperCase()} ({item.size}),
                          </p>
                        </div>
                      );
                    })}
                  </td>
                  {/* <td>{order.total}</td> */}
                  {/* <td>
                    {order.tracking_number && order.fulfilled ? (
                      <p>{order.tracking_number}</p>
                    ) : (
                      <input
                        required
                        type="text"
                        placeholder="Tracking #"
                        onChange={(e) => setTrackingNo(e.target.value)}
                      />
                    )}
                  </td> */}
                  {/* <td>
                    {order.provider && order.fulfilled ? (
                      <p>{order.provider}</p>
                    ) : (
                      <input
                        required
                        type="text"
                        placeholder="Provider"
                        onChange={(e) => setProvider(e.target.value)}
                      />
                    )}
                  </td> */}
                  {/* <td>
                    <input
                      required
                      type="text"
                      placeholder="Provider"
                      disabled={order.provider ? true : false}
                      value={order.provider !== '' ? order.provider : ''}
                      onChange={(e) => setProvider(e.target.value)}
                    />
                  </td> */}
                  <td>
                    {order.fulfilled ? (
                      <p className="p-4 text-center bg-white border opacity-50 border-secondary text-secondary">
                        already sent
                      </p>
                    ) : (
                      <button
                        // style={{
                        //   width: '100%',
                        //   fontSize: '16px',
                        //   padding: '1rem',
                        //   backgroundColor: 'green',
                        //   color: 'white',
                        // }}
                        className="w-full primary-btn-small"
                        onClick={() => [
                          HandleSubmit(order.id),
                          NotionExport(order),
                        ]}
                        disabled={order.fulfilled}
                      >
                        dispatch
                      </button>
                    )}
                    <button
                      style={{
                        width: '100%',
                        padding: '1rem',
                        fontSize: '14px',
                        color: 'grey',
                      }}
                      // className="primary-btn-small"
                      onClick={() => CancelOrder()}
                    >
                      cancel
                    </button>
                    {/* <button onClick={() => NotionExport(order)}>Notion</button> */}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderList;
